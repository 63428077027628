export default {
    data: [
        {
            thematic: "agriculture",
            winners: [
                {
                    prefix: "ms",
                    name: "Gouegni Edwige Flore",
                    gender: "female",
                    coResearchers: [

                    ],
                    centre: "Neglected Tropical Diseases and Forensic Biotechnology",
                    country: "Nigeria",
                    researchTopic: "Immunogenic Potentials Of Trypanosoma Congolense Flagellar Pocket Membrane Bound Acid Phosphatase",
                },
                {
                    prefix: "mr",
                    name: "Magangi Wilfred Abincha",
                    gender: "male",
                    coResearchers: [

                    ],
                    centre: "West Africa Centre for Crop Improvement",
                    country: "Ghana",
                    researchTopic: "Breeding Investigations to Support Cassava Biofortification through Genomics, Phenomics and Bioinformatics",
                },
                {
                    prefix: "mr",
                    name: "Krampah Eric Appiah",
                    gender: "male",
                    coResearchers: [

                    ],
                    centre: "Africa Centre of Excellence in Coastal Resilience",
                    country: "Ghana",
                    researchTopic: "Laboratory rearing of mangrove oyster (Crassostrea tulipa) larvae on local microalgae isolates",
                }
            ]
        },
        {
            thematic: "environment",
            winners: [
                {
                    prefix: "ms",
                    name: "Djassou Adjoavi Colette",
                    gender: "female",
                    coResearchers: [

                    ],
                    centre: "Valorisation des Dechets en Produits a Haute Valeur Ajoutee",
                    country: "Cote d’Ivoire",
                    researchTopic: "Elaboration d’éco matériaux pour la construction à partir de béton incorporant des granulats de pneus usagés",
                },
                {
                    prefix: "ms",
                    name: "Ouedraogo Habibou",
                    gender: "female",
                    coResearchers: [
                        {
                            prefix : "dr",
                            name : "Sore Seick Omar"
                        },
                        {
                            prefix : "dr",
                            name : "Nshimiyimana Philbert"
                        }

                    ],
                    centre: "Collège d'Ingénerie: Institut International de l'Eau et de l'Environnement",
                    country: "Burkina Faso",
                    researchTopic: "Durabilité des briques en terre comprimées stabilisées aux géopolymères",
                },
                {
                    prefix: "ms",
                    name: "Maduka Chinonye Medline",
                    gender: "female",
                    coResearchers: [

                    ],
                    centre: "Oilfield Chemicals Research",
                    country: "Nigeria",
                    researchTopic: "The use of organics to formulate brake fluid",
                }
            ]
        },
        {
            thematic: "health",
            winners: [
                {
                    prefix: "ms",
                    name: "Yongoikyo Kumawuese Abigail",
                    gender: "female",
                    coResearchers: [

                    ],
                    centre: " Food Technology and Research",
                    country: "Nigeria",
                    researchTopic: "Formulation Of Infant Food with Fortified Mango Fruit Flour",
                },
                {
                    prefix: "dr",
                    name: "Traore Maïga Safiatou",
                    gender: "female",
                    coResearchers: [

                    ],
                    centre: "Centre de Formation, de Recherche et d’Expertises en Sciences du Medicament",
                    country: "Burkina Faso",
                    researchTopic: "Developement D’une Creme A Base De Miel Et De Beurre De Karite Du Burkina Faso Pour Le Traitement Des Brulures Cutanees",
                },
                {
                    prefix: "mr",
                    name: "Aliyu Mukhtar",
                    gender: "male",
                    coResearchers: [

                    ],
                    centre: "Neglected Tropical Diseases and Forensic Biotechnology",
                    country: "Nigeria",
                    researchTopic: "Prevalence of Microsporidia in Association with Plasmodium Falciparum and Wuchereria Bancrofti in Anopheles Gambiae Within Ahmadu Bello University, Zaria (Samaru Campus).",
                }
            ]
        },
        {
            thematic: "stem",
            winners: [
                {
                    prefix: "mr",
                    name: "Ozoude Chinemerem Jerry ",
                    gender: "male",
                    coResearchers: [
                        {
                            prefix : "mr",
                            name : "Basi Kingsley Job"
                        }
                    ],
                    centre: "Sustainable Power and Energy Development",
                    country: "Nigeria",
                    researchTopic: "Design, optimization, and fabrication of perovskite solar cells for low-cost manufacturing of solar panels in Africa",
                },
                {
                    prefix: "mr",
                    name: "Diallo Al Hassim",
                    gender: "male",
                    coResearchers: [

                    ],
                    centre: "Centre d’Excellence Africain Mathématiques, Informatique et TIC",
                    country: "Senegal",
                    researchTopic: "Un système d’information hospitalier intégré pour le dépistage et le suivi de la drépanocytose",
                },
                {
                    prefix: "ms",
                    name: "Iji Juliana Omonya",
                    gender: "female",
                    coResearchers: [
                        {
                            prefix : "ms",
                            name : "Uzochukwu Maryann Ifeoma"
                        },
                        {
                            prefix : "ms",
                            name : "Odili Cynthia Ujuh"
                        }
                    ],
                    centre: "New Pedagogy in Engineering Education",
                    country: "Nigeria",
                    researchTopic: "Research Topic: Production of a novel low-cost water filtration device for removal of toxic heavy metals and pathogens: sustainable fabrication and a blended teaching pedagogy.",
                }
            ]
        }
    ]
}