<template>
  <div class="criteria">
      <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>{{$t("winners")}}</h2>
      </div>
    </div>

    <section id="events" class="events">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-12 align-items-stretch">
            <div class="card">
              <div class="card-img">
                <img src="/assets/img/banner1.png">
              </div>
              
            </div>
          </div>
          <div class="row">
            <div class="card mt-5" v-for="(awardee, a) in awardees" :key="`date-${a}`">
                <div class="card-body">
                    <h5 class="card-title"><a href="#">{{$t(awardee.thematic)}}</a></h5>
                    <div class="icon-boxes d-flex flex-column justify-content-center">
                        <div class="row">
                            <!-- <div class="col-xl-3 d-flex align-items-stretch">
                                <div class="icon-box mt-4 mt-xl-0">
                                    <h5><b>ddgdg</b></h5>
                                </div>
                            </div> -->
                            <div class="icon-boxes d-flex flex-column justify-content-left">
                                <div class="row" v-for="(winner, w) in awardee.winners" :key="`winner-${w}`">
                                    <div class="col-xl-12  my-2">
                                        <div class="icon-box mt-4 mt-xl-0">
                                            <table class="table">
                                                 <tr>
                                                    <td>{{$t('name')}}</td>
                                                    <td><b>{{`${$t(winner.prefix)} ${winner.name}`}}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('gender')}}</td>
                                                    <td><b>{{$t(winner.gender)}}</b></td>
                                                </tr>
                                                <tr v-if="winner.coResearchers.length > 0">
                                                    <td>{{$t('co-researchers')}}</td>
                                                    <td><b v-for="(researcher, r) in winner.coResearchers" :key="`researcher-${r}`">{{`${$t(researcher.prefix)} ${researcher.name}`}}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('centre')}}</td>
                                                    <td><b>{{winner.centre}}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('country')}}</td>
                                                    <td><b>{{winner.country}}</b></td>
                                                </tr>
                                                 <tr>
                                                    <td>{{$t('project-title')}}</td>
                                                    <td><b>{{winner.researchTopic}}</b></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import awardees from "../data/winners"
export default {
    data(){
        return {
            awardees : awardees.data
        }
    },
    created(){
        
    }
}
</script>

<style>

</style>